const AmericanTireTheme = {
  breakpoints: {
    keys: ["xs", "sm", "md", "lg", "xl"],
    values: { xs: 0, lg: 1280, sm: 600, xl: 1920, md: 960 },
  },
  direction: "ltr",
  mixins: {
    toolbar: {
      minHeight: 56,
      "@media (min-width:0px) and (orientation: landscape)": {
        minHeight: 48,
      },
      "@media (min-width:600px)": { minHeight: 64 },
    },
  },
  palette: {
    common: {
      black: "#000",
      white: "#fff",
    },
    type: "light",
    primary: {
      light: "#e73634",
      main: "#e20402",
      dark: "#9e0201",
      contrastText: "#fff",
    },
    secondary: {
      light: "#626484",
      main: "#373737",
      dark: "#292b47",
      contrastText: "#fff",
    },
    info: {
      light: "#ebebf3",
      main: "#e6e7f1",
      dark: "#a1a1a8",
      contrastText: "#fff",
    },
    error: {
      light: "#c12033",
      main: "#c12033",
      dark: "#c12033",
      contrastText: "#fff",
    },
    success: {
      light: "#4cbd97",
      main: "#4cbd97",
      dark: "#4cbd97",
      contrastText: "#fff",
    },
    footer1: {
      main: "#424242",
    },
    footer2: {
      main: "#212121",
    },
    gray: {
      50: "#fafafa",
      100: "#f5f5f5",
      200: "#eeeeee",
      300: "#e0e0e0",
      400: "#bdbdbd",
      500: "#9e9e9e",
      600: "#757575",
      700: "#616161",
      800: "#424242",
      900: "#212121",
      A100: "#d5d5d5",
      A200: "#aaaaaa",
      A400: "#303030",
      A700: "#616161",
    },
    contrastThreshold: "3",
    augmentColor: "f I()",
    tonalOffset: "0.2",
    text: {
      primary: "#333",
      secondary: "#333399",
    },
    divider: "rgba(0,0,0,0.12)",
    background: {
      paper: "#fff",
      default: "#e8e8e8",
    },
  },
  shadows: [
    "none",
    "0px 1px 3px 0px rgba(0, 0, 0, 0.2),0px 1px 1px 0px rgba(0, 0, 0, 0.14),0px 2px 1px -1px rgba(0, 0, 0, 0.12)",
    "0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
    "0px 1px 8px 0px rgba(0, 0, 0, 0.2),0px 3px 4px 0px rgba(0, 0, 0, 0.14),0px 3px 3px -2px rgba(0, 0, 0, 0.12)",
    "0px 2px 4px -1px rgba(0, 0, 0, 0.2),0px 4px 5px 0px rgba(0, 0, 0, 0.14),0px 1px 10px 0px rgba(0, 0, 0, 0.12)",
    "0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 5px 8px 0px rgba(0, 0, 0, 0.14),0px 1px 14px 0px rgba(0, 0, 0, 0.12)",
    "0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 6px 10px 0px rgba(0, 0, 0, 0.14),0px 1px 18px 0px rgba(0, 0, 0, 0.12)",
    "0px 4px 5px -2px rgba(0, 0, 0, 0.2),0px 7px 10px 1px rgba(0, 0, 0, 0.14),0px 2px 16px 1px rgba(0, 0, 0, 0.12)",
    "0px 5px 5px -3px rgba(0, 0, 0, 0.2),0px 8px 10px 1px rgba(0, 0, 0, 0.14),0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
    "0px 5px 6px -3px rgba(0, 0, 0, 0.2),0px 9px 12px 1px rgba(0, 0, 0, 0.14),0px 3px 16px 2px rgba(0, 0, 0, 0.12)",
    "0px 6px 6px -3px rgba(0, 0, 0, 0.2),0px 10px 14px 1px rgba(0, 0, 0, 0.14),0px 4px 18px 3px rgba(0, 0, 0, 0.12)",
    "0px 6px 7px -4px rgba(0, 0, 0, 0.2),0px 11px 15px 1px rgba(0, 0, 0, 0.14),0px 4px 20px 3px rgba(0, 0, 0, 0.12)",
    "0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 12px 17px 2px rgba(0, 0, 0, 0.14),0px 5px 22px 4px rgba(0, 0, 0, 0.12)",
    "0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 13px 19px 2px rgba(0, 0, 0, 0.14),0px 5px 24px 4px rgba(0, 0, 0, 0.12)",
    "0px 7px 9px -4px rgba(0, 0, 0, 0.2),0px 14px 21px 2px rgba(0, 0, 0, 0.14),0px 5px 26px 4px rgba(0, 0, 0, 0.12)",
    "0px 8px 9px -5px rgba(0, 0, 0, 0.2),0px 15px 22px 2px rgba(0, 0, 0, 0.14),0px 6px 28px 5px rgba(0, 0, 0, 0.12)",
    "0px 8px 10px -5px rgba(0, 0, 0, 0.2),0px 16px 24px 2px rgba(0, 0, 0, 0.14),0px 6px 30px 5px rgba(0, 0, 0, 0.12)",
    "0px 8px 11px -5px rgba(0, 0, 0, 0.2),0px 17px 26px 2px rgba(0, 0, 0, 0.14),0px 6px 32px 5px rgba(0, 0, 0, 0.12)",
    "0px 9px 11px -5px rgba(0, 0, 0, 0.2),0px 18px 28px 2px rgba(0, 0, 0, 0.14),0px 7px 34px 6px rgba(0, 0, 0, 0.12)",
    "0px 9px 12px -6px rgba(0, 0, 0, 0.2),0px 19px 29px 2px rgba(0, 0, 0, 0.14),0px 7px 36px 6px rgba(0, 0, 0, 0.12)",
    "0px 10px 13px -6px rgba(0, 0, 0, 0.2),0px 20px 31px 3px rgba(0, 0, 0, 0.14),0px 8px 38px 7px rgba(0, 0, 0, 0.12)",
    "0px 10px 13px -6px rgba(0, 0, 0, 0.2),0px 21px 33px 3px rgba(0, 0, 0, 0.14),0px 8px 40px 7px rgba(0, 0, 0, 0.12)",
    "0px 10px 14px -6px rgba(0, 0, 0, 0.2),0px 22px 35px 3px rgba(0, 0, 0, 0.14),0px 8px 42px 7px rgba(0, 0, 0, 0.12)",
    "0px 11px 14px -7px rgba(0, 0, 0, 0.2),0px 23px 36px 3px rgba(0, 0, 0, 0.14),0px 9px 44px 8px rgba(0, 0, 0, 0.12)",
    "0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14),0px 9px 46px 8px rgba(0, 0, 0, 0.12)",
  ],
  typography: {
    htmlFontSize: 16,
    fontFamily: "Open Sans, sans serif",
    fontSize: 16,
    fontWeightLight: "300",
    fontWeightRegular: "400",
    fontWeightMedium: "500",
    fontWeightBold: "700",
    h1: {
      fontFamily: "Open Sans, sans serif",
      fontWeight: "700",
      fontSize: "2em",
      lineHeight: "1.167",
      letterSpacing: "-0.01562em",
    },
    h2: {
      fontFamily: "Open Sans, sans serif",
      fontWeight: "700",
      fontSize: "4.5rem",
      lineHeight: "1.2",
      letterSpacing: "-0.00833em",
    },
    h3: {
      fontFamily: "Open Sans, sans serif",
      fontWeight: "700",
      fontSize: "3rem",
      lineHeight: "1.167",
      letterSpacing: "0em",
    },
    h4: {
      fontFamily: "Open Sans, sans serif",
      fontWeight: "700",
      fontSize: "2.125rem",
      lineHeight: "1.235",
      letterSpacing: "0.00735em",
    },
    h5: {
      fontFamily: "Open Sans, sans serif",
      fontWeight: "700",
      fontSize: "1.75rem",
      lineHeight: "1.334",
      letterSpacing: "0em",
    },
    h6: {
      fontFamily: "Open Sans, sans serif",
      fontWeight: "700",
      fontSize: "1.5rem",
      lineHeight: "1.6",
      letterSpacing: "0.0075em",
    },
    subtitle1: {
      fontFamily: "Open Sans, sans serif",
      fontWeight: "500",
      fontSize: "1.5rem",
      lineHeight: "1.75",
      letterSpacing: "0.00938em",
    },
    subtitle2: {
      fontFamily: "Open Sans, sans serif",
      fontWeight: "700",
      fontSize: "1.3rem",
      lineHeight: "1.75",
      letterSpacing: "0.00938em",
    },
    body1: {
      fontFamily: "Open Sans, sans serif",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "1.5",
      letterSpacing: "0.00938em",
    },
    body2: {
      fontFamily: "Open Sans, sans serif",
      fontWeight: "400",
      fontSize: "2.25rem",
      lineHeight: "1.5",
      letterSpacing: "0.00938em",
    },
    button: {
      fontFamily: "Open Sans, Sans Serif",
      fontWeight: "700",
      fontSize: "1.75rem",
      lineHeight: "1.75rem",
      letterSpacing: "0.02857em",
      textTransform: "none",
    },
    caption: {
      fontFamily: "Open Sans, sans serif",
      fontWeight: "400",
      fontSize: "1.5rem",
      lineHeight: "1.66",
      letterSpacing: "0.03333em",
    },
    overline: {
      fontFamily: "Open Sans, sans serif",
      fontWeight: "400",
      fontSize: "1.5rem",
      lineHeight: "2.66",
      letterSpacing: "0.08333em",
      textTransform: "none",
    },
  },
  spacing: 2,
  mui: true,
  shape: {
    borderRadius: "4",
  },
  transitions: {
    easing: {
      easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
      easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
      easeIn: "cubic-bezier(0.4, 0, 1, 1)",
      sharp: "cubic-bezier(0.4, 0, 0.6, 1)",
    },
    duration: {
      standard: "300",
      short: "250",
      enteringScreen: "225",
      shorter: "200",
      leavingScreen: "195",
      shortest: "150",
      complex: "375",
    },
  },
  zIndex: {
    modal: "1300",
    snackbar: "1400",
    drawer: "1200",
    appBar: "1100",
    mobileStepper: "1000",
    tooltip: "1500",
    speedDial: "1050",
  },
  overrides: {
    MuiSelect: {
      select: {
        paddingRight: "18px !important",
      },
    },
  },
};

export default AmericanTireTheme;
