import { createTheme } from "@material-ui/core/styles";
import themeBBT from "./styles/theme-bbt.js";
import themeTW from "./styles/theme-tw.js";
import themeATD from "./styles/theme-atd";
import themeTTU from "./styles/theme-ttu";

const appBrandId = 3;
const appBuildVersion = "isomorphicEngineV2.4.0.0";

let siteCss;
let faviconEndPoint;
let theme;
let appBrandName;
let appBrandLogoImg;
let appBrandLogoMarkImg;
let appPhoneNumber;
let appGTMId;
let appSlug;
let appBrandLinks = Object.assign(
  {},
  ...[
    "facebook",
    "instagram",
    "youtube",
    "linkedin",
    "twitter",
    "pinterest",
  ].map((key) => ({ [key]: null }))
);

switch (appBrandId) {
  case 1:
    siteCss = require("./styles/bbt-site.css");
    faviconEndPoint = "bbt.ico";
    theme = createTheme(themeBBT);
    appBrandName = "Big Brand Tire & Service";
    appBrandLogoImg =
      "https://venomstorage.blob.core.windows.net/bbtwebblob/images/big-brand-tire-and-service-logo.png";
    appBrandLogoMarkImg =
      "https://venomstorage.blob.core.windows.net/bbtwebblob/images/big-brand-tire-and-service-logo-mark.png";
    appPhoneNumber = "(866) 779-8473";
    appGTMId = "GTM-W2MGFS";
    appSlug = "https://www.bigbrandtire.com";
    appBrandLinks = {
      ...appBrandLinks,
      facebook: `https://www.facebook.com/bigbrandtireandservice`,
      instagram: `https://www.instagram.com/bigbrandtire`,
      youtube: `https://www.youtube.com/bigbrandtire`,
      linkedin: `https://www.linkedin.com/company/big-brand-tire-&-service`,
      twitter: `https://twitter.com/bigbrandtire`,
      pinterest: `https://www.pinterest.com/bigbrandtire`,
    };
    break;
  case 2:
    siteCss = require("./styles/tw-site.css");
    faviconEndPoint = "tw.ico";
    theme = createTheme(themeTW);
    appBrandName = "Tire World Auto Repair";
    appBrandLogoImg =
      "https://venomstorage.blob.core.windows.net/bbtwebblob/images/tire-world-auto-repair-logo.png";
    appBrandLogoMarkImg =
      "https://venomstorage.blob.core.windows.net/bbtwebblob/images/tire-world-auto-repair-logo-mark.png";
    appPhoneNumber = "(844) 640-6915";
    appGTMId = "GTM-M4Q7RS6";
    appSlug = "https://www.tireworldinc.com";
    appBrandLinks = {
      ...appBrandLinks,
      facebook: `https://www.facebook.com/tireworldautorepair`,
      instagram: `https://www.instagram.com/tireworldautorepair`,
      youtube: `https://www.youtube.com/@tireworldautorepair818`,
      linkedin: `https://www.linkedin.com/company/tire-world-auto-repair-tire-pros`,
    };
    break;
  case 3:
    siteCss = require("./styles/atd-site.css");
    faviconEndPoint = "atd.ico";
    theme = createTheme(themeATD);
    appBrandName = "American Tire Depot";
    appBrandLogoImg =
      "https://venomstorage.blob.core.windows.net/bbtwebblob/images/american-tire-depot-logo.png";
    appBrandLogoMarkImg =
      "https://venomstorage.blob.core.windows.net/bbtwebblob/images/american-tire-depot-logo-mark.png";
    appPhoneNumber = "(800) 326-6483";
    appGTMId = "GTM-PFQZ69C";
    appSlug = "https://www.americantiredepot.com";
    appBrandLinks = {
      ...appBrandLinks,
      facebook: `https://www.facebook.com/americantiredepotcorporate`,
      instagram: `https://www.instagram.com/americantiredepot`,
      youtube: `https://www.youtube.com/user/americantiredepot`,
      linkedin: `https://www.linkedin.com/company/american-tire-depot`,
    };
    break;
  case 4:
    siteCss = require("./styles/atd-site.css");
    faviconEndPoint = "tires2u.ico";
    theme = createTheme(themeTTU);
    appBrandName = "Tires To You";
    appBrandLogoImg =
      "https://venomstorage.blob.core.windows.net/bbtwebblob/images/tires-to-you-primary-logo.png";
    appBrandLogoMarkImg =
      "https://venomstorage.blob.core.windows.net/bbtwebblob/images/tires2u-logo-mark.png";
    appPhoneNumber = "(210) 625-7485";
    appGTMId = "GTM-K7NVD4RH";
    appSlug = "https://www.4tires2u.com";
    appBrandLinks = {
      ...appBrandLinks,
    };
    break;
}

const APP_ENV = {
  appBrandId,
  appBuildVersion: `${appBuildVersion}_${appBrandId}`,
  siteCss,
  favicon: `${window.location.origin}/favicon/${faviconEndPoint}`,
  theme,
  appBrandName,
  appBrandLogoImg,
  appBrandLogoMarkImg,
  appPhoneNumber,
  appGTMId,
  appSlug,
  appBrandLinks,
  googleMapsApiKey: "AIzaSyALt4dDxIxljD2VPRRkPLNy6X162VvnPOU",
};

export default APP_ENV;
